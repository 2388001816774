// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-album-dato-cms-album-slug-js": () => import("./../../../src/pages/album/{DatoCmsAlbum.slug}.js" /* webpackChunkName: "component---src-pages-album-dato-cms-album-slug-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modalidade-dato-cms-modalidade-slug-js": () => import("./../../../src/pages/modalidade/{DatoCmsModalidade.slug}.js" /* webpackChunkName: "component---src-pages-modalidade-dato-cms-modalidade-slug-js" */),
  "component---src-pages-modalidades-js": () => import("./../../../src/pages/modalidades.js" /* webpackChunkName: "component---src-pages-modalidades-js" */),
  "component---src-pages-noticia-dato-cms-news-slug-js": () => import("./../../../src/pages/noticia/{DatoCmsNews.slug}.js" /* webpackChunkName: "component---src-pages-noticia-dato-cms-news-slug-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

